import { IconName } from '@fortawesome/fontawesome-svg-core';
import { 
  IconPackageKey,
  IconSize, 
  IconAnimation, 
  IconRotation, 
  IconFlip,
  IconSize as IconSizeObj,
  IconAnimation as IconAnimationObj,
  IconRotation as IconRotationObj,
  IconFlip as IconFlipObj
} from './icon.types';

export abstract class IconBase {
  protected icons: IconName[] = [];
  protected packages: IconPackageKey[] = [];
  protected sizes: IconSize[] = [];
  protected animations: IconAnimation[] = [];
  protected rotations: IconRotation[] = [];
  protected flips: IconFlip[] = [];
  protected classes: string[] = [];

  protected initialize(
    name: IconName | IconName[],
    initialPackage: IconPackageKey = 'regular',
    initialSize: IconSize = 'none',
    initialAnimation: IconAnimation = 'none',
    initialRotation: IconRotation = 'none',
    initialFlip: IconFlip = 'none',
    initialClass: string = ''
  ): void {
    this.icons = this.normalizeInput(name);
    this.packages = this.normalizeInput(initialPackage);
    this.sizes = this.normalizeInput(initialSize);
    this.animations = this.normalizeInput(initialAnimation);
    this.rotations = this.normalizeInput(initialRotation);
    this.flips = this.normalizeInput(initialFlip);
    this.classes = this.normalizeInput(initialClass);
  }

  protected normalizeInput<T>(input: T | T[]): T[] {
    return Array.isArray(input) ? input : [input];
  }

  protected getValue<T>(array: T[], index: number): T {
    return array[index] ?? array[0];
  }

  protected buildClassString(index: number): string {
    const classes: string[] = []; 
    const size = this.getValue(this.sizes, index);
    if (size !== 'none') {
      classes.push(IconSizeObj[size]);
    }

    const animation = this.getValue(this.animations, index);
    if (animation !== 'none') {
      classes.push(IconAnimationObj[animation]);
    } else {
      const rotation = this.getValue(this.rotations, index);
      if (rotation !== 'none') {
        classes.push(IconRotationObj[rotation]);
      }
      const flip = this.getValue(this.flips, index);
      if (flip !== 'none') {
        classes.push(IconFlipObj[flip]);
      }
    }

    const className = this.getValue(this.classes, index);
    if (className) {
      classes.push(className);
    }

    return classes.join(' ');
  }
}