import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { IconPackageKey, IconSize, IconAnimation, IconRotation, IconFlip, getPackagePrefix } from './icon.types';
import { IconBase } from './icon.base';

@Component({
  selector: 'app-icon',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  template: `
    <ng-container *ngIf="!isStacked; else stackedTemplate">
      <fa-icon [icon]="[getPackageAsPrefix(0), icons[0]]" [class]="getClassString(0)"> </fa-icon>
    </ng-container>
    <ng-template #stackedTemplate>
      <fa-stack class="fa-stack" [class]="stackClass">
        <fa-icon
          *ngFor="let _ of icons; let i = index"
          [icon]="[getPackageAsPrefix(i), icons[i]]"
          [class]="getClassString(i)"
          [stackItemSize]="i === 0 ? '2x' : '1x'"
        >
        </fa-icon>
      </fa-stack>
    </ng-template>
  `,
  styles: [
    `
      fa-stack {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        height: 100%;
      }

      :host {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      fa-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      // Assure que l'icône elle-même est centrée
      :host ::ng-deep fa-icon svg {
        vertical-align: middle;
      }
    `,
  ],
})
export class IconComponent extends IconBase implements OnInit {
  @Input() set name(value: IconName | IconName[]) {
    this.initialize(
      value,
      this.getValue(this.packages, 0),
      this.getValue(this.sizes, 0),
      this.getValue(this.animations, 0),
      this.getValue(this.rotations, 0),
      this.getValue(this.flips, 0),
      this.getValue(this.classes, 0),
    );
  }
  @Input() set package(value: IconPackageKey | IconPackageKey[]) {
    this.packages = this.normalizeInput(value);
  }
  @Input() set size(value: IconSize | IconSize[]) {
    this.sizes = this.normalizeInput(value);
  }
  @Input() set animation(value: IconAnimation | IconAnimation[]) {
    this.animations = this.normalizeInput(value);
  }
  @Input() set rotation(value: IconRotation | IconRotation[]) {
    this.rotations = this.normalizeInput(value);
  }
  @Input() set flip(value: IconFlip | IconFlip[]) {
    this.flips = this.normalizeInput(value);
  }
  @Input() set class(value: string | string[]) {
    this.classes = this.normalizeInput(value);
  }

  @Input() fixedWidth = false;
  @Input() stackClass = '';

  isStacked = false;

  @HostBinding('class') hostClass = '';

  ngOnInit(): void {
    this.isStacked = this.icons.length > 1;
  }

  getPackageAsPrefix(index: number): IconPrefix {
    const packageKey = this.getValue(this.packages, index);
    return getPackagePrefix(packageKey);
  }

  getClassString(index: number): string {
    const baseClasses = super.buildClassString(index);
    return this.fixedWidth ? `${baseClasses} fa-fw` : baseClasses;
  }
}
