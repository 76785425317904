import { IconPrefix } from '@fortawesome/fontawesome-svg-core';

export const IconPackage = {
  solid: 'fas',
  regular: 'far',
  light: 'fal',
  duotone: 'fad',
} as const;
export type IconPackageKey = keyof typeof IconPackage;
export type IconPackageValue = (typeof IconPackage)[IconPackageKey];

export const IconSize = {
  '2xs': 'fa-2xs',
  xs: 'fa-xs',
  sm: 'fa-sm',
  none: '',
  lg: 'fa-lg',
  xl: 'fa-xl',
  '2xl': 'fa-2xl',
  '3xl': 'fa-3xl',
  '4xl': 'fa-4xl',
  '5xl': 'fa-5xl',
  '6xl': 'fa-6xl',
  '7xl': 'fa-7xl',
  '8xl': 'fa-8xl',
  '9xl': 'fa-9xl',
} as const;
export type IconSize = keyof typeof IconSize;

export const IconAnimation = {
  none: '',
  beat: 'fa-beat',
  'beat-fade': 'fa-beat-fade',
  bounce: 'fa-bounce',
  fade: 'fa-fade',
  flip: 'fa-flip',
  shake: 'fa-shake',
  spin: 'fa-spin',
  'spin-reverse': 'fa-spin-reverse',
  'spin-pulse': 'fa-spin-pulse',
} as const;
export type IconAnimation = keyof typeof IconAnimation;

export const IconRotation = {
  none: '',
  rotate90: 'fa-rotate-90',
  rotate180: 'fa-rotate-180',
  rotate270: 'fa-rotate-270',
} as const;
export type IconRotation = keyof typeof IconRotation;

export const IconFlip = {
  none: '',
  horizontal: 'fa-flip-horizontal',
  vertical: 'fa-flip-vertical',
  both: 'fa-flip-both',
} as const;
export type IconFlip = keyof typeof IconFlip;

export const getPackagePrefix = (key: IconPackageKey): IconPrefix => IconPackage[key] as IconPrefix;